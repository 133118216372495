import classNames from 'classnames';
import type { VFC } from 'react';
import React from 'react';

import type { InfoFieldProps } from './InfoFieldProps';
import InfoInput from './InfoInput';

interface SubEntityFieldProps extends Pick<InfoFieldProps, 'field' | 'setValue' | 'onSaveData'> {
  inputType: 'number' | 'text';
  disabled?: boolean;
  mongoSaveDisabled?: boolean;
  integrationSaveDisabled?: boolean;
  values?: { [k: string]: any };
  disableWarning?: boolean;
}

const SubEntityField: VFC<SubEntityFieldProps> = ({
  field,
  inputType,
  disabled,
  integrationSaveDisabled,
  mongoSaveDisabled,
  values,
  onSaveData,
  setValue,
  disableWarning
}) => {
  const subEntityFieldValue =
    values?.subEntities?.[values[field.params.getByValue] + field.params.entityType]?.data?.[field.value as any];

  if (!subEntityFieldValue) {
    return null;
  }

  const className = classNames({
    'Info-CompactInput': true,
    disabledInputField: mongoSaveDisabled,
    readOnlyInputField: integrationSaveDisabled
  });

  return (
    <InfoInput
      key="5"
      field={field}
      disabled={!!disabled}
      className={className}
      inputType={inputType}
      fieldValue={subEntityFieldValue}
      mongoSaveDisabled={!!mongoSaveDisabled}
      integrationSaveDisabled={!!integrationSaveDisabled}
      setValue={setValue}
      onSaveData={onSaveData}
      disableWarning={disableWarning}
    />
  );
};

export default React.memo(SubEntityField);
