import UserTimeLogsApi from './UserTimeLogsApi';
import { getCurrentSeconds, consoleLogUserTimeLogs } from './utils';
import type { UserTimeLogsRow } from './types';

const AUTOSAVE_TIMEOUT = 1000 * 60 * 15;

class UserTimeLogs {
  private startedAt: number | null = null;
  private activeTicketId: string | null = null;
  private unSentLogs: UserTimeLogsRow[] = [];
  private autoSaveTimeout: NodeJS.Timeout | null = null;

  public getActiveTicketId() {
    return this.activeTicketId;
  }

  public setActiveTicketId = async (ticketId: string) => {
    if (ticketId === this.activeTicketId) return;
    
    await this.clearState();
    this.startedAt = getCurrentSeconds();
    this.activeTicketId = ticketId;
    this.setupAutoSave();
  };

  private sendLogs = async () => {
    consoleLogUserTimeLogs('[userTimeLogs]: unSentLogs', this.unSentLogs);
    if (this.unSentLogs.length === 0) return;

    try {
      await UserTimeLogsApi.sendUserTimeLogs(this.unSentLogs);
      this.unSentLogs = [];
    } catch (error) {
      consoleLogUserTimeLogs('[userTimeLogs]: Error sending logs', error);
    }
  };

  public clearState = async (opts?: { autoSave: boolean }) => {
    if (!this.activeTicketId || !this.startedAt) return;

    const now = getCurrentSeconds();
    const elapsedDuration = now - this.startedAt;

    this.unSentLogs.push({
      id: this.activeTicketId,
      duration: elapsedDuration
    });

    if (!opts?.autoSave) {
      this.startedAt = null;
      this.activeTicketId = null;
    } else {
      this.startedAt = now;
    }

    await this.sendLogs();
    this.setupAutoSave();
  };

  setupAutoSave = () => {
    if (this.autoSaveTimeout) {
      clearTimeout(this.autoSaveTimeout);
    }
    this.autoSaveTimeout = setTimeout(() => {
      this.clearState({ autoSave: true });
    }, AUTOSAVE_TIMEOUT);
  };
}

export default new UserTimeLogs();
