import { t as tr } from 'i18next';
import React from 'react';

import type { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';

import { TicketStatusAfterCommentSubmit } from './types';
import { commentsVC } from 'src/api/VersionControl';
import { channelNameToType } from 'src/Components/CommentIconContent/ChannelType';
import { parseJsonContent } from 'src/Components/ReplyMethods/components/ReplyVCDrafts';
import { DATE_TIME_SECONDS_FORMAT, getPrettyDate } from 'src/Utilities/dates';
import { taskIdToNumericalId } from 'src/Utilities/ticketList';

import type { KeyDownEvent } from 'src/Components/Case/ReplyEditor';
import type { ViewMode } from 'src/Components/Versions/VersionViewer';
import type { Channels } from 'src/types/Channel';
import type { Attachment } from 'src/types/Ticket';

export interface ReplyMethodProps<S> {
  taskId: string;
  updateState: (taskId: string, channel: Channels, state: Partial<S>) => void;
  attachments?: Attachment[];
  smallButtons?: boolean;
}

const AUTOSAVE_TIMEOUT = 1000 * 60 * 15;

export abstract class ReplyMethod<P extends ReplyMethodProps<S>, S> extends React.PureComponent<P, S> {
  abstract getDraftChannel(): Channels;

  abstract getDraftState(state: S): Partial<S>;

  abstract submitComment(ticketStatus?: TicketStatusAfterCommentSubmit): void;

  private autoSaveIntervalId?: NodeJS.Timeout;

  private _isMounted: boolean;

  constructor(props: P) {
    super(props);
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.autoSaveIntervalId = setInterval(() => this.saveVCDraft(true), AUTOSAVE_TIMEOUT);
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.saveVCDraft(true);

    clearInterval(this.autoSaveIntervalId);
  }

  saveDraft = (state: S) => {
    this.props.updateState(this.props.taskId, this.getDraftChannel(), this.getDraftState(state));
  };

  updateState(update: Partial<S>) {
    if (!this._isMounted) {
      this.saveDraft({ ...this.state, ...update });
      return;
    }

    this.setState(
      (previousState: S) => {
        return {
          ...previousState,
          ...update
        };
      },
      () => {
        this.saveDraft(this.state);
      }
    );
  }

  updateWithPreviousState(func: (previous: S) => void) {
    this.setState(
      (previous) => func(previous),
      () => this.saveDraft(this.state)
    );
  }

  saveVCDraft = (isAutoSave = false) => {
    const channel = channelNameToType(this.getDraftChannel());
    const id = taskIdToNumericalId(this.props.taskId);
    const { content, contentTemplate } = this.state as any;

    if ((!content || content === contentTemplate) && isAutoSave) {
      return;
    }

    commentsVC.sendDraft({
      id,
      channelId: channel,
      content: JSON.stringify({
        ...this.state,
        content
      }),
      attachments: [],
      forceSave: true
    });
  };

  loadVCDraft = async (_mode: ViewMode, draftId: number) => {
    const taskId = taskIdToNumericalId(this.props.taskId);
    const draft = await commentsVC.loadDraftById(taskId, draftId);

    if (draft && draft.content) {
      try {
        const state = parseJsonContent(draft.content);
        this.setState(state);
      } catch (e) {
        console.error(e);
      }
    }
  };

  handleHotKeys = (event: KeyDownEvent) => {
    if (event.key === 'Enter' && (event.ctrlKey || event.metaKey) && !event.repeat) {
      this.submitComment();
    } else if (event.key === 's' && event.altKey && !event.repeat) {
      this.submitComment(TicketStatusAfterCommentSubmit.done);
    }
  };

  getAttachmentsOptions = (attachments?: Attachment[]): DropdownItemProps[] =>
    (attachments ?? this.props.attachments ?? [])
      .filter((attachment) => !attachment.isDuplicated && !attachment.deprecated && !attachment.isQuarantined)
      .map(
        (attachment) =>
          ({
            text: attachment.fileName,
            value: attachment.id,
            uploaded: attachment.uploaded,
            isQuarantined: attachment.isQuarantined,
            description: getPrettyDate(attachment.uploaded, { format: DATE_TIME_SECONDS_FORMAT }),
            label: attachment.isQuarantined ? { color: 'red', empty: true, circular: true } : ''
          } as DropdownItemProps)
      )
      .concat({
        text: tr('ATTACHMENT_ADD_DROPDOWN'),
        value: 'ADD_ATTACHMENT'
      });
}
