import React, { Component, KeyboardEventHandler } from 'react';
import CreatableSelect from 'react-select/creatable';

import { defaultCreatableSelectStyles } from '../Utilities';

const components = {
  DropdownIndicator: null
};

export interface Option {
  readonly label: string;
  readonly value: string;
  readonly color?: string;
}

const createOption = (label: string) => ({
  label,
  value: label
});

interface IProps {
  value: Option[] | [];
  placeholder?: string;
  handleValueChange: (value: Option[]) => void;
}

interface IState {
  readonly inputValue: string;
}

export default class MultiSelectInput extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      inputValue: ''
    };
  }

  handleInputChange = (inputValue: string) => {
    this.setState({ inputValue });
  };

  handleKeyDown: KeyboardEventHandler = (event) => {
    const { inputValue } = this.state;
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        this.setState({
          inputValue: ''
        });
        this.props.handleValueChange([...this.props.value, createOption(inputValue)]);
        event.preventDefault();
    }
  };

  render() {
    const { inputValue } = this.state;

    return (
      <CreatableSelect
        styles={{
          ...defaultCreatableSelectStyles,
          container: () => ({
            width: '100%'
          })
        }}
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        placeholder={this.props.placeholder}
        menuIsOpen={false}
        onChange={(value, action) => this.props.handleValueChange(value as Option[])}
        onInputChange={this.handleInputChange}
        onKeyDown={this.handleKeyDown}
        value={this.props.value}
      />
    );
  }
}
