import { isEmpty } from 'lodash';
import { phone } from 'phone';

import EnvSettings from 'src/api/EnvSettings';
import { CommentMetadataType } from 'src/types/Ticket';
import { FieldTypes } from '../types/Info';
import { addComment } from 'src/actions/commentsActions';
import { fieldValueReplaceStrings, parseFieldValue } from 'src/Components/Utilities/infoField';
import type { Entity } from 'src/types/Ticket';
import type { PersonalData } from 'src/types/User';
import type { Ticket } from 'src/types/Ticket';
import type { TicketType } from 'src/types/TicketType';

const valueToArray = (value?: string | string[] | number | number[]) => {
  if (!value) return [];
  return Array.isArray(value) ? value : [value];
};

interface OpenExternalLinkProps {
  ticketTypes: TicketType[];
  userData: PersonalData;
  task: Ticket;
  entity: Entity;
  normalizedPhoneNumber: string;
}

const openExternalLink = ({ ticketTypes, userData, task, entity, normalizedPhoneNumber }: OpenExternalLinkProps) => {
  return (dispatch: any) => {
    const selectedTicketType = ticketTypes.find(({ name }) => task.taskType === name);
    const customerInfoFields =
      selectedTicketType?.fieldSets.map((fieldSet) => fieldSet.customerInfo).find(Boolean) || [];

    const { fieldName, channel, inBackground, direction } = EnvSettings.getSettings().OPEN_EXTERNAL_LINK.data;
    const fieldNames = valueToArray(fieldName);
    const channels = valueToArray(channel);

    const fields = customerInfoFields.filter(
      ({ name, customType }) => customType === FieldTypes.link && name && fieldNames.includes(name)
    );
    const isChannelsNotSetOrMatched = !channels?.length || channels.includes(task.channel);
    const isDirectionNotSetOrMatched = !direction?.length || direction.includes(task.originalDirection);
    const isValidPhoneNumber = phone(normalizedPhoneNumber).isValid;

    if (
      !fields.length ||
      isEmpty(entity) ||
      !isChannelsNotSetOrMatched ||
      !isDirectionNotSetOrMatched ||
      !isValidPhoneNumber
    ) {
      return;
    }

    const links = fields.map((field) => {
      const fieldValue = parseFieldValue({
        field,
        values: entity,
        shouldEncodeUriComponent: true,
        personalData: userData,
        task
      });

      return fieldValueReplaceStrings(fieldValue, field);
    });

    if (!links.length) {
      return;
    }

    links.forEach((link) => {
      inBackground ? fetch(link).catch(console.error) : window.open(link, '_blank');

      // add comment open external link
      dispatch(
        addComment(task.id, {
          type: 'automatic',
          content: '',
          metaData: { type: CommentMetadataType.openExternalLink, value: link }
        })
      );
    });
  };
};

export default openExternalLink;
