import React from 'react';
import classNames from 'classnames';
import { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import type { GestureState } from 'react-with-gesture';
import type { History } from 'history';
import type { vector2 } from 'react-with-gesture';

import ApiConfig from './api/ApiConfig';
import FeatureFlags from './api/FeatureFlags';
import UserTimeLogs from './api/UserTimeLog/UserTimeLogs';
import ModalLoaderComponent from './Components/Loader/ModalLoader';
import TicketsSidebar from './Components/Sidebar/TicketsSidebar';
import InfoPagesSidebar from './Components/Sidebar/InfoPagesSidebar';
import CaseContainer from './containers/CaseContainer';
import InfopageCaseContainer from './containers/InfopageCaseContainer';
import SettingsContainer from './containers/SettingsContainer';
import TicketTabBarContainer from './containers/TicketTabBarContainer';
import urlActionContainer from './containers/urlActionContainer';
import ErrorBoundary from './ErrorBoundary';
import Layout from 'src/Components/Layout/Layout';
import PhoneIntegrations from 'src/Components/PhoneIntegrations';
import RedirectRole from './RedirectRole';
import ReportingPage from './ReportingPage';
import TicketListTab from 'src/Components/ticketList/TicketListTab';
import TicketListTabBarContainer from 'src/containers/TicketListTabBarContainer';
import ActivateFeatureModal from 'src/Components/generic/ActivateFeatureModal';
import EntityViewer from 'src/Components/Management/Entities/EntityViewer';
import type { PersonalData } from './types/User';

import '../node_modules/semantic-ui-css/semantic.min.css';
import './App.css';

const ExportDataContainer = React.lazy(() => import('./containers/ExportDataContainer'));

interface MainProps {
  history: History;
  location: Location;
  mobileMode: boolean;
  leftTabOpen: boolean;
  userPersonalData: PersonalData;

  onUp(delta: vector2): void;
}

interface MainState {
  visible: boolean;
  showExportDialog: boolean;
  initialPosition: vector2;
  endPosition: vector2;
}

const hideTicketListPaths = ['/settings', '/entities'];

class Main extends Component<MainProps, MainState> {
  constructor(props: MainProps) {
    super(props);

    this.state = {
      visible: true,
      showExportDialog: false,
      initialPosition: [0, 0],
      endPosition: [0, 0]
    };
  }

  private leavePromptEnabled(): boolean {
    return FeatureFlags.isFlagOn('ENABLE_USER_TIME_LOGGING') && process.env.NODE_ENV !== 'development';
  }

  /**
   * Prompt message on leaging or refreshing page
   * sending time active log to database
   * @param e
   */
  private handleLeavePage(e: BeforeUnloadEvent) {
    UserTimeLogs.clearState({ autoSave: true });
    // https://stackoverflow.com/questions/40570164/how-to-customize-the-message-changes-you-made-may-not-be-saved-for-window-onb
    const confirmationMessage = 'Are you sure ?';
    e.returnValue = confirmationMessage;
    return confirmationMessage;
  }

  private onToggleExportData = () => {
    this.setState(() => ({
      showExportDialog: !this.state.showExportDialog
    }));
  };

  private onUp = (state: GestureState) => {
    this.props.onUp(state.delta);
    this.setState({ endPosition: state.delta });
  }; // event => eventHandler, respond to touchstart/mousedown events within React's render cycle

  private onDown = (state: GestureState) => {
    this.setState({ initialPosition: state.initial });
  }; // event => eventHandler, respond to mouseup/touchend events within React's render cycle

  componentDidMount() {
    // Alert on closing or refreshing page
    if (this.leavePromptEnabled()) {
      window.addEventListener('beforeunload', this.handleLeavePage);
    }
  }

  componentWillUnmount() {
    // Alert on closing or refreshing page
    if (this.leavePromptEnabled()) {
      window.removeEventListener('beforeunload', this.handleLeavePage);
    }
  }

  render() {
    // Change to "hideTicketList"
    const hideTicketList = hideTicketListPaths.some((path) => this.props.location.pathname.includes(path));
    const mobileSideMenuClass = classNames({
      hiddenContainer: !this.props.leftTabOpen || hideTicketList
    });
    const mobileContentClass = classNames({
      detailViewMobile: true,
      hiddenContainer: this.props.leftTabOpen && !hideTicketList
    });

    return (
      <ErrorBoundary>
        {this.state.showExportDialog && (
          <React.Suspense fallback={ModalLoaderComponent}>
            <ExportDataContainer handleClose={this.onToggleExportData} isOpen={this.state.showExportDialog} />
          </React.Suspense>
        )}
        {ApiConfig.getConfig().REDIRECT_ROLE6 !== undefined && <RedirectRole />}

        <Layout
          mobileMode={this.props.mobileMode}
          onClickExportData={this.onToggleExportData}
          onDown={this.onDown}
          onUp={this.onUp}
        >
          {this.props.mobileMode ? (
            <div
              className={mobileSideMenuClass}
              style={{
                width: '100%',
                minWidth: 'auto',
                height: 'auto',
                maxHeight: 'auto',
                overflowY: 'auto',
                overflowX: 'hidden',
                display: hideTicketList ? 'none' : 'block'
              }}
            >
              <ErrorBoundary>
                <TicketListTabBarContainer />
              </ErrorBoundary>
              <div className="flexSideView">
                <ErrorBoundary>
                  <TicketListTab contentType="tickets" />
                </ErrorBoundary>

                {this.props.userPersonalData.permissions.includes('allowPhoneIntegrations') && (
                  <ErrorBoundary>
                    <PhoneIntegrations />
                  </ErrorBoundary>
                )}
              </div>
            </div>
          ) : (
            <ErrorBoundary>
              <Switch>
                <Route
                  path={['/infopage', '/infopage/:id']}
                  render={(routeProps) => (
                    <InfoPagesSidebar
                      {...routeProps}
                      userPersonalData={this.props.userPersonalData}
                      key={(routeProps.match.params as { id: string }).id}
                    />
                  )}
                />
                <Route>
                  <TicketsSidebar userPersonalData={this.props.userPersonalData} onSettingsPage={hideTicketList} />
                </Route>
              </Switch>
            </ErrorBoundary>
          )}

          <div className={this.props.mobileMode ? mobileContentClass : 'detailView'}>
            <ErrorBoundary>
              <Switch>
                {!this.props.mobileMode && FeatureFlags.isFlagOn('ENABLE_KNOWLEDGE_BASE') && (
                  <Route
                    path={['/infopage', '/infopage/:id']}
                    render={(routeProps) => (
                      <TicketTabBarContainer
                        {...routeProps}
                        contentType="infopages"
                        key={(routeProps.match.params as { id: string }).id}
                      />
                    )}
                  />
                )}
                <Route path={'/entities'} />
                <Route>
                  <TicketTabBarContainer contentType="tickets" />
                </Route>
              </Switch>

              <Switch>
                {FeatureFlags.isFlagOn('ENABLE_ENTITY_VIEWER') && <Route path="/entities/" component={EntityViewer} />}
                <Route
                  path="/case/:id"
                  exact={true}
                  render={(routeProps) => <CaseContainer {...routeProps} key={routeProps.match.params.id} />}
                />

                {!this.props.mobileMode && FeatureFlags.isFlagOn('ENABLE_KNOWLEDGE_BASE') && (
                  <Route
                    path="/infopage/:id"
                    render={(routeProps) => <InfopageCaseContainer {...routeProps} key={routeProps.match.params.id} />}
                  />
                )}
                {!FeatureFlags.isFlagOn('ENABLE_KNOWLEDGE_BASE') && (
                  <Route path="/feature" component={ActivateFeatureModal} />
                )}

                <Route path="/actions/:type" component={urlActionContainer} />
                <Route path="/reporting" component={ReportingPage} exact={true} />
                <Route
                  path={['/settings/:pageName', '/settings']}
                  render={(...props) => (
                    <SettingsContainer noMenuContainer={this.props.mobileMode} {...(props as any)} />
                  )}
                />
              </Switch>
            </ErrorBoundary>
          </div>
        </Layout>
      </ErrorBoundary>
    );
  }
}

export default Main;
