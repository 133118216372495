import { createAsyncThunk } from '@reduxjs/toolkit';
import { t } from 'i18next';
import iziToast from 'izitoast';
import debounce from 'lodash/debounce';
import { Cookies } from 'react-cookie';

import type { AxiosError } from 'axios';
import type { ActionCreator, Dispatch } from 'redux';
import type { ThunkAction } from 'redux-thunk';

import { NOT_ACCEPTABLE } from '../Utilities/httpClients';
import { accessTokenRefresher } from '../Utilities/token';
import {
  ACTIVATION_SUCCESS,
  LOGIN_FAILURE_CREDENTIALS,
  LOGIN_FAILURE_GENERAL,
  LOGIN_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_SUCCESS
} from './index';
import { fetchFeatureFlagsAndInfoPages } from './infoPagesActions';
import { fetchLinkLists } from './linkListActions';
import { removeAllNotifications } from './notificationsActions';
import { fetchTags } from './tagActions';
import {
  clearDetailedTickets,
  fetchChannelTypes,
  fetchResponseTemplates,
  fetchTickets,
  fetchTicketTypes,
  fetchTitleTemplates
} from './ticketsActions';
import { fetchPersonalData, fetchUsers } from './userActions';
import { clearInfopagelistFilter } from 'src/actions/infoPagesActionsRTK';
import { clearTicketlistFilter } from 'src/actions/ticketListTabActionsRTK';
import AuthApi from 'src/api/AuthApi';
import SocketInstance from 'src/realTimeNotifications';
import { unsetShowImages } from 'src/reducers/commentsMenuReducer';
import { StaticTabs } from 'src/types/TicketList';

import type { State } from 'src/types/initialState';
import type { ThunkAppDispatch } from 'src/types/store';

export const loginSuccess = () => {
  return { type: LOGIN_SUCCESS };
};

export const loginFailureCredentials = () => {
  return { type: LOGIN_FAILURE_CREDENTIALS };
};

export const loginFailureGeneral = () => {
  return { type: LOGIN_FAILURE_GENERAL };
};

export const logoutSuccess = () => {
  return { type: LOGOUT_SUCCESS };
};

export const logoutFailure = () => {
  return { type: LOGOUT_FAILURE };
};

export const activationSuccess = () => {
  return { type: ACTIVATION_SUCCESS };
};

export const loginExternal: ActionCreator<ThunkAction<any, State, any, any>> = (token: string, username: string) => {
  return async (dispatch: Dispatch) => {
    return AuthApi.loginExternal(token, username)
      .then((response) => {
        if (response.hasLoggedIn) {
          dispatch(loginSuccess());
          dispatch(fetchUsers('') as any);
          dispatch(fetchTags() as any);
        } else if (response.hasEnteredIncorrectLoginCredentials) {
          dispatch(loginFailureCredentials());
        } else {
          dispatch(loginFailureGeneral());
        }
      })
      .catch((error) => {
        console.error('Error while logging externally', error);
        dispatch(loginFailureGeneral());
      });
  };
};

export const loginExternalByBackend: ActionCreator<ThunkAction<any, State, any, any>> = () => {
  return async (dispatch: Dispatch) => {
    return AuthApi.loginBackend()
      .then(() => {
        dispatch(loginSuccess());
        dispatch(fetchUsers() as any);
        dispatch(fetchTicketTypes() as any);
        dispatch(fetchChannelTypes() as any);
        dispatch(fetchResponseTemplates() as any);
        dispatch(fetchTitleTemplates() as any);
        dispatch(fetchLinkLists() as any);
        dispatch(fetchTags() as any);
        dispatch(fetchPersonalData() as any);
        dispatch(fetchTickets(null, StaticTabs.MAIN_VIEW) as any);
        dispatch(fetchFeatureFlagsAndInfoPages() as any);
      })
      .catch((error: any) => {
        console.error('Error while logging in by backend', error);
      });
  };
};

export const activate: ActionCreator<ThunkAction<any, State, any, any>> = (token: string, password: string) => {
  return async (dispatch: Dispatch) => {
    return AuthApi.activate(token, password)
      .then(() => {
        dispatch(activationSuccess());
      })
      .catch((error) => {
        console.error('Error while activating user', error);
        return false;
      });
  };
};

export const forgotPassword: ActionCreator<ThunkAction<any, State, any, any>> = (email: string) => {
  return async () => {
    return AuthApi.forgotPasswordRequest(email).catch((error) => {
      console.error('Error while sending request', error);
      return error;
    });
  };
};

export const refreshToken: ActionCreator<ThunkAction<any, State, any, any>> =
  (refresherActivation?: boolean) => async (dispatch) => {
    const loggedIn = localStorage.getItem('loggedIn');

    try {
      const accessTokenExpiration = await AuthApi.refreshToken();
      localStorage.setItem('accessTokenExpiration', accessTokenExpiration);
    } catch (error) {
      console.error('Error while refreshing token', error);
      if ((error as AxiosError)?.response?.status === NOT_ACCEPTABLE) {
        dispatch(performUserLogoutWhenTokenHasExpired());
        return;
      }
    }

    if (loggedIn && refresherActivation) {
      try {
        await accessTokenRefresher(() => dispatch(refreshToken(true)));
      } catch (error) {
        dispatch(performUserLogoutWhenTokenHasExpired());
      }
    }
  };

export const login: ActionCreator<ThunkAction<any, State, any, any>> = (user: string, password: string) => {
  return async (dispatch: Dispatch) => {
    return AuthApi.login(user, password)
      .then((response) => {
        if (response.hasLoggedIn) {
          dispatch(loginSuccess());
          dispatch(fetchTickets(null, StaticTabs.MAIN_VIEW) as any);
          dispatch(fetchFeatureFlagsAndInfoPages() as any);
          return true;
        } else if (response.hasEnteredIncorrectLoginCredentials) {
          dispatch(loginFailureCredentials());
          return false;
        } else {
          dispatch(loginFailureGeneral());
          return false;
        }
      })
      .catch((error) => {
        console.error('Error while logging in by default way', error);
        dispatch(loginFailureGeneral());
        return false;
      });
  };
};

export const logout = createAsyncThunk<void, { type?: 'automatic' | 'manual' } | undefined, { rejectValue: Error }>(
  'logout',
  async (args, { dispatch, getState }) => {
    SocketInstance.disconnect();
    dispatch(removeAllNotifications());
    if (args?.type && args?.type === 'manual') {
      clearActiveFilters(dispatch, getState as () => State);
    }

    try {
      await AuthApi.logout();

      dispatch(logoutSuccess());
      dispatch(unsetShowImages());
    } catch (error) {
      console.error('Error while logout in authAction', error);
      dispatch(logoutFailure());
    } finally {
      removeAuthTraces();
      dispatch(clearDetailedTickets());
    }
  }
);

export const dispatchExpiredLogoutOnce = debounce(
  (dispatch: ThunkAppDispatch) => dispatch(performUserLogoutWhenTokenHasExpired()),
  3000,
  { leading: true, trailing: false }
);

export const dispatchRefreshTokenOnce = debounce(
  (dispatch: ThunkAppDispatch, refresherActivation) => dispatch(refreshToken(refresherActivation)),
  3000,
  { leading: true, trailing: false }
);

const performUserLogoutWhenTokenHasExpired = createAsyncThunk<void, undefined, { rejectValue: Error }>(
  'performUserLogoutWhenTokenHasExpired',
  async (argument, { dispatch }) => {
    dispatch(logout());
    iziToast.info({
      message: t('auth.tokenExpired.message'),
      timeout: 10000,
      position: 'bottomRight',
      icon: 'icon privacy'
    });
  }
);

export const clearActiveFilters = (dispatch: Dispatch, getState: () => State) => {
  const {
    router: {
      location: { pathname }
    }
  } = getState();
  const isInfopage = pathname.startsWith('/infopage');
  const tabId = [
    ...(isInfopage ? getState().infoPageListTabs.values() : Object.values(getState().ticketListTabs))
  ].find((t: any) => t.activeTab)?.id;
  if (tabId) {
    dispatch((isInfopage ? clearInfopagelistFilter : clearTicketlistFilter)(tabId));
  }
};

const removeAuthTraces = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('loggedIn');
  localStorage.removeItem('accessTokenExpiration');

  const cookies = new Cookies();
  cookies.remove('eeedo', { domain: 'oc-cloud2.elisa.fi' });
  cookies.remove('eeedo', { path: '/' });
  cookies.remove('eeedo_refresh', { path: '/' });
  // cookies.remove('__HOST-eeedo', { path: '/' });
  cookies.remove('loggedIn', { path: '/' });
  cookies.remove('eeedocore', { path: '/' });
};
