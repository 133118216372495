import iziToast from 'izitoast';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import type Keycloak from 'keycloak-js';

import EnreachVoiceIntegration from 'src/Utilities/intergrations/callEnreachVoice';
import type { State } from 'src/types/initialState';
import { publicHttpClient } from 'src/Utilities/httpClients';

interface DiscoveryPayload {
  siloName: string;
  username: string;
  apiEndpoint: string;
  uiEndpoint: string;
  authority: string;
  authorityIdPHint: string;
  basicAuthEndpoint: string;
  recoveryEndpoint: string;
}

export const EnreachSSO = () => {
  const { t } = useTranslation();
  const userData = useSelector((state: State) => state.userData);
  const username = !import.meta.env.DEV ? userData.profile.email : 'user.1@eee.do';
  const [identityData, setIdentityData] = useState<DiscoveryPayload | null>(null);
  const [keycloakInstance, setKeycloakInstance] = useState<Keycloak | null>(null);

  useEffect(() => {
    // Use case after redirect from authorization
    const returnMsg: { messageType: string; ok: boolean } = {
      messageType: 'KCLoginResult',
      ok: false
    };

    if (document.location.href.includes('code=') && window.opener) {
      returnMsg.ok = true;
      window.opener.postMessage(returnMsg, '*');
      window.close();
    } else {
      checkContext();
    }

    window.addEventListener('message', loginCallbackHandler);
    return () => {
      window.removeEventListener('message', loginCallbackHandler);
    };
  }, []);

  useEffect(() => {
    if (identityData) {
      setupKeycloakInstance();
    }
  }, [identityData]);

  useEffect(() => {
    if (keycloakInstance) {
      initKeyclockAuth();
    }
  }, [keycloakInstance]);

  const checkContext = async () => {
    try {
      const response = await publicHttpClient.get(`https://discover.enreachvoice.com/api/user/?user=${username}`);
      if (response.data[0].authority) {
        setIdentityData(response.data[0]);
      } else {
        throw new Error('No authority provided in the response');
      }
    } catch (error) {
      console.error('[Enreach] Failed to get context: ', error);
    }
  };

  const setupKeycloakInstance = () => {
    import('keycloak-js').then(async (Keycloak) => {
      try {
        const identityUrl = new URL(identityData!.authority);
        const keycloak = new Keycloak.default({
          url: identityUrl!.origin,
          realm: identityUrl!.pathname.split('/')[2],
          clientId: window.location.host
        });
        setKeycloakInstance(keycloak);
      } catch (error) {
        console.error('[Enreach] Error during keyclock initialization: ', error);
      }
    });
  };

  const initKeyclockAuth = async () => {
    try {
      const authenticated = await keycloakInstance!.init({
        onLoad: 'check-sso',
        scope: 'beneapi',
        silentCheckSsoRedirectUri: `${location.origin}/silent-check-sso.html`,
        responseMode: 'query'
      });

      if (authenticated) {
        await webRTCSuccessSSOAuthorization();
      } else {
        const loginParams: Record<string, string> = {
          loginHint: username,
          redirectUri: window.location.href
        };
        if (identityData!.authorityIdPHint) {
          loginParams.idpHint = identityData!.authorityIdPHint;
        }
        const loginUrl = keycloakInstance!.createLoginUrl(loginParams);
        const authPopup = window.open(loginUrl, '_blank', 'width=500,height=500,resizable=yes');

        if (!authPopup) {
          iziToast.warning({
            title: 'Enreach',
            message: t('enreach.blocked_redirect', { value: loginUrl }),
            timeout: 5000,
            close: true
          });
        }
      }
    } catch (error) {
      console.error('[Enreach] Error during keyclock authorization: ', error);
    }
  };

  const loginCallbackHandler = async (event: MessageEvent) => {
    if (event.data) {
      if (event.data.messageType == 'KCLoginResult') {
        if (!event.origin.includes(window.location.host)) {
          return;
        }

        // Reinitialize keycloak instance - to refresh cookies
        await checkContext();
      }
    }
  };

  const webRTCSuccessSSOAuthorization = async () => {
    await EnreachVoiceIntegration.getWebRTC()?.loginUsingSSO(username, true, keycloakInstance!);
  };

  return null;
};
