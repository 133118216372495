import React from 'react';
import { t as tr } from 'i18next';
import { Form, Input, TextArea } from 'semantic-ui-react';

import MultiSelectInput, { Option } from '../../MultiSelectInput/MultiSelectInput';
import type { ReplySalesforceState, SalesforceFormPayload } from '../ReplySalesforce';

interface IProps {
  validationErrors: { [key: string]: any };
  payload: SalesforceFormPayload;

  handleSetState: (fields: Partial<ReplySalesforceState['payload']>) => void;
}

class SalesforceEmailForm extends React.Component<IProps> {
  render() {
    const arrayEmailErrorKey = Object.keys(this.props.validationErrors).find((key) =>
      key.match(/Emails\[([0-9])*\].value/)
    );

    return (
      <>
        <Form.Field
          required
          error={
            this.props.validationErrors.Emails
              ? {
                  content: this.props.validationErrors.Emails,
                  pointing: 'above'
                }
              : arrayEmailErrorKey && {
                  content: this.props.validationErrors[arrayEmailErrorKey],
                  pointing: 'above'
                }
          }
          control={Input}
          label={tr('GENERAL_EMAILS')}
        >
          <MultiSelectInput
            value={this.props.payload.Emails || []}
            placeholder={tr('salesforce_reply.list_emails')}
            handleValueChange={(value: Option[]) =>
              this.props.handleSetState({
                Emails: value
              })
            }
          />
        </Form.Field>

        <Form.Field
          required
          error={
            this.props.validationErrors.Description && {
              content: this.props.validationErrors.Description,
              pointing: 'above'
            }
          }
          control={TextArea}
          value={this.props.payload.Description}
          label={tr('GENERAL_CONTENT')}
          onChange={(event: React.SyntheticEvent, data: { [key: string]: any }) =>
            this.props.handleSetState({
              Description: data.value
            })
          }
        />
      </>
    );
  }
}

export default SalesforceEmailForm;
