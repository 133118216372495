import React from 'react';
import { t as tr } from 'i18next';
import moment from 'moment';
import { Form, Input, TextArea, Checkbox, CheckboxProps, Popup, Label, Icon } from 'semantic-ui-react';

import DateTimePicker from '../../DateTimePicker';
import SalesforceMemoSelect from './SalesforceMemoSelect';
import { ISalesforceSettingsData } from 'src/api/EnvSettings';
import { replaceObjectParameters } from '../../Utilities';
import { DATE_TIME_FORMAT, getPrettyDate } from 'src/Utilities/dates';
import type { IGeneralSalesforceData } from 'src/types/Salesforce';
import type { ReplySalesforceState, SalesforceFormPayload } from '../ReplySalesforce';

interface IProps {
  validationErrors: { [key: string]: any };
  salesforceSettings: ISalesforceSettingsData;
  payload: SalesforceFormPayload;
  contacts: IGeneralSalesforceData[];
  users: IGeneralSalesforceData[];

  handleSetState: (fields: Partial<ReplySalesforceState['payload']>) => void;
  handleSelectChange: (property: string, value: string) => void;
  setSearchableSelectFields: (type: 'contact' | 'user' | 'account', stringToSearch: string) => void;
}

interface IState {
  isDueDateEnabled: boolean;
}

class SalesforceTaskForm extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isDueDateEnabled: false
    };
  }

  render() {
    return (
      <>
        {!this.props.salesforceSettings.hiddenFields.includes('task.Subject') && (
          <Form.Field
            required
            error={
              this.props.validationErrors.Subject && {
                content: this.props.validationErrors.Subject,
                pointing: 'above'
              }
            }
            control={Input}
            value={this.props.payload.Subject}
            label={tr('GENERAL_SUBJECT')}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              this.props.handleSetState({
                Subject: event.target.value
              })
            }
          />
        )}

        {!this.props.salesforceSettings.hiddenFields.includes('task.Description') && (
          <Form.Field
            error={
              this.props.validationErrors.Description && {
                content: this.props.validationErrors.Description,
                pointing: 'above'
              }
            }
            control={TextArea}
            value={this.props.payload.Description}
            label={tr('GENERAL_DESCRIPTION')}
            onChange={(event: React.SyntheticEvent, data: { [key: string]: any }) =>
              this.props.handleSetState({
                Description: data.value
              })
            }
          />
        )}

        {!this.props.salesforceSettings.hiddenFields.includes('task.Status') && (
          <SalesforceMemoSelect
            value={this.props.payload.Status}
            fieldName="Status"
            items={this.props.salesforceSettings.taskStatuses.map((statusName: string) => ({
              text: statusName,
              value: statusName
            }))}
            errorMessage={this.props.validationErrors.Status}
            handleState={this.props.handleSelectChange}
            label={tr('GENERAL_STATUS')}
          />
        )}

        {!this.props.salesforceSettings.hiddenFields.includes('task.ActivityDate') && (
          <>
            <Checkbox
              toggle
              onClick={(event: React.MouseEvent<HTMLInputElement, MouseEvent>, data: CheckboxProps) => {
                this.setState({ isDueDateEnabled: !this.state.isDueDateEnabled }, () => {
                  this.props.handleSetState({
                    ActivityDate: this.state.isDueDateEnabled ? moment().add(30, 'days').unix() : undefined
                  });
                });
              }}
              label={tr('salesforce_reply.set_due_date')}
              style={{ marginBottom: '10px' }}
            />

            {this.state.isDueDateEnabled && (
              <Form.Field>
                <label>{tr('GENERAL_DUE_DATE')}</label>

                <Popup
                  position="top center"
                  wide={true}
                  hoverable={true}
                  flowing={true}
                  trigger={
                    <DateTimePicker
                      onChange={(value: any) =>
                        this.props.handleSetState({
                          ActivityDate: value
                        })
                      }
                      trigger={
                        <Label>
                          <Icon name="time" />
                          <span>{getPrettyDate(this.props.payload.ActivityDate, { format: DATE_TIME_FORMAT })}</span>
                        </Label>
                      }
                      value={this.props.payload.ActivityDate}
                    />
                  }
                  content={tr('TOOLTIP_DUEDATE')}
                />
              </Form.Field>
            )}
          </>
        )}

        {!this.props.salesforceSettings.hiddenFields.includes('task.Contact') && (
          <SalesforceMemoSelect
            value={this.props.payload.WhoId}
            fieldName="WhoId"
            items={this.props.contacts
              .sort((a, b) => (a.Name > b.Name ? 1 : -1))
              .map((singleItem) => ({
                text: this.props.salesforceSettings.contactFieldView
                  ? replaceObjectParameters(this.props.salesforceSettings.contactFieldView, singleItem)
                  : singleItem.Name,
                value: singleItem.Id
              }))}
            errorMessage={this.props.validationErrors.WhoId}
            handleState={this.props.handleSelectChange}
            onSearchChange={(event: React.SyntheticEvent, data: { [key: string]: any }) =>
              this.props.setSearchableSelectFields('contact', data.searchQuery)
            }
            label={tr('GENERAL_CONTACT')}
            search
            clearable
          />
        )}

        {!this.props.salesforceSettings.hiddenFields.includes('task.Owner') && (
          <SalesforceMemoSelect
            value={this.props.payload.OwnerId || this.props.salesforceSettings.taskDefaultOwnerId}
            fieldName="OwnerId"
            items={this.props.users
              .sort((a, b) => (a.Name > b.Name ? 1 : -1))
              .map((singleItem) => ({
                text: this.props.salesforceSettings.userFieldView
                  ? replaceObjectParameters(this.props.salesforceSettings.userFieldView, singleItem)
                  : singleItem.Name,
                value: singleItem.Id
              }))}
            errorMessage={this.props.validationErrors.OwnerId}
            handleState={this.props.handleSelectChange}
            onSearchChange={(event: React.SyntheticEvent, data: { [key: string]: any }) =>
              this.props.setSearchableSelectFields('user', data.searchQuery)
            }
            label={tr('GENERAL_OWNER')}
            search
          />
        )}
      </>
    );
  }
}

export default SalesforceTaskForm;
