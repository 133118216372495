import { filterNonDefaultTags, filterTagsByCategoryIds, filterTagsByTagIds } from 'src/types/Tag';
import type { Entity } from 'src/types/Ticket';
import type { Field } from 'src/types/Info';
import type { FieldSet, TicketType, Widget } from 'src/types/TicketType';
import type { Tag } from 'src/types/Tag';
import type { Ticket } from 'src/types/Ticket';

const getTicketWidgetValue = (ticket: Ticket, widget: Widget, tags: Tag[]) => {
  switch (widget.name) {
    case 'Tags': {
      const categoryIds = widget.options?.categoryIds || [];
      const ticketTags = filterTagsByTagIds(tags, ticket.tags);
      return categoryIds.length ? filterTagsByCategoryIds(ticketTags, categoryIds) : filterNonDefaultTags(ticketTags);
    }
    case 'Delegations':
      return ticket.delegatedTo;
    case 'CaseChannel':
      return ticket.channel;
    case 'LinkLists':
      return ticket['linkLists'];
    default:
      return ticket[widget.name];
  }
};

const getTranslation = (context: string, field: string) => {
  switch (context) {
    case 'Widgets':
      return 'CASE_TITLE_' + field.toUpperCase();
    case 'Fieldsets':
      return 'PLACEHOLDER_CUSTOMER_' + field.toUpperCase();
    default:
      return field;
  }
};

const ticketCloseCheck = (restrictions: any[], restrictionType: string) => {
  return restrictionType === 'Fieldset'
    ? restrictions.every((f) => f?.every((field: FieldSet) => field.valueExists === true))
    : restrictions.every((widget: Widget) => widget.valueExists === true);
};

const getWidgetMinLength = ({ name, options }: Widget) => {
  if (typeof options?.minLength === 'number') {
    return options?.minLength;
  }

  return name === 'Tags' || name === 'Delegations' ? 1 : 0;
};

const getWidgetMaxLength = ({ options }: Widget) => {
  if (typeof options?.maxLength === 'number') {
    return options?.maxLength;
  }
  return null;
};

// TODO: requires fundamental refactoring to avoid data mutations
export const checkMandatoryFieldsForTicketClosing = (task: Ticket, ticketTypes: TicketType[], tags: Tag[]) => {
  let canTicketBeClosed: { status: string; fields: string[] } = { status: 'OK', fields: [] };
  const ticketType = ticketTypes.find((ticketType: TicketType) => ticketType.name === task.taskType);
  if (!ticketType) {
    return { status: 'NO TICKETTYPE', fields: [] };
  }

  const widgetRestrictions = ticketType.widgetOrder
    .filter((widget): widget is Widget => typeof widget !== 'string' && widget.mandatoryForTicketClosing === true)
    .map((widget) => ({
      ...widget,
      valueExists: null
    }));

  const fieldSetRestrictions = ticketType.fieldSets
    .map((f: FieldSet) => {
      const idField = f[f.id] ?? f['customerInfo'];

      return idField
        .filter((obj: Field) => obj.params?.mandatoryForTicketClosing === true)
        .map((field: Field) => ({ ...field, valueExists: null, fieldset: f.id }));
    })
    .filter((f) => f.length);

  if (widgetRestrictions.length > 0) {
    widgetRestrictions.forEach((widget: Widget) => {
      const value = getTicketWidgetValue(task, widget, tags);
      const minLength = getWidgetMinLength(widget);
      const maxLength = getWidgetMaxLength(widget);
      widget.valueExists =
        (Array.isArray(value) ? value.length >= minLength : value !== '') &&
        (Array.isArray(value) && typeof maxLength === 'number' ? value.length <= maxLength : true);
    });
  }

  if (fieldSetRestrictions.length > 0) {
    fieldSetRestrictions.forEach((f: Entity[] | undefined) => {
      f?.forEach((x: Entity) => {
        const value = x.value;
        if (x.fieldset === 'caseInfo') {
          x.valueExists = task?.case?.[value] !== '';

          return x;
        } else {
          return task.entities.map((entity: Entity) => {
            x.valueExists = entity?.data?.[value] !== '';

            return x;
          });
        }
      });
    });
  }

  const fieldSetCheck = ticketCloseCheck(fieldSetRestrictions, 'Fieldset');
  const widgetCheck = ticketCloseCheck(widgetRestrictions, 'Widgets');

  if (!fieldSetCheck || !widgetCheck) {
    let unfilledFields: string[] = [];
    let missingWidgets: string[] = [];

    if (!widgetCheck) {
      missingWidgets = widgetRestrictions
        .filter((obj: Widget) => obj && (obj.valueExists === false || obj.valueExists === null))
        .map((o: Widget) => o.displayName || getTranslation('Widgets', o.name));

      unfilledFields = missingWidgets;
    }
    if (!fieldSetCheck) {
      const fields: string[] = [];
      const missingFieldSets = fieldSetRestrictions.map((obj: FieldSet[]) =>
        obj.filter((nestedObj: FieldSet) => obj && (nestedObj.valueExists === false || nestedObj.valueExists === null))
      );

      // TODO: figure out type
      const flattenedFieldsetRestrictions: any[] = [...missingFieldSets];
      flattenedFieldsetRestrictions.forEach((fieldsetField: FieldSet) => {
        if (fieldsetField.fieldset === 'customerInfo' && task.entities.length === 0) {
          fields.unshift('TAB_NAME_CUSTOMER');
        }
        fields.push(fieldsetField.name);
      });

      unfilledFields = [...fields, ...missingWidgets];
    }

    unfilledFields = unfilledFields?.filter((field: string) => field.length);

    canTicketBeClosed = { status: 'NOT OK', fields: unfilledFields || [] };
  }

  return canTicketBeClosed;
};
