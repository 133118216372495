import i18n from 'i18next';
import moment from 'moment';
import 'moment/dist/locale/fi';
import 'moment/dist/locale/sv';
import 'moment/dist/locale/en-gb';
import 'moment/dist/locale/nl-be';

interface DateConfiguration {
  sinceCurrentTime?: boolean;
  format?: string;
}

export const DATE_DAY_MONTH_FORMAT = 'D.MM';
export const DATE_DASHED_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_SECONDS_DASHED_FORMAT = `${DATE_DASHED_FORMAT} HH:mm:ss`;
export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_TIME_FORMAT = `${DATE_FORMAT} HH:mm`;
export const DATE_TIME_SECONDS_FORMAT = `${DATE_FORMAT} HH:mm:ss`;
export const TIME_SECONDS_FORMAT = `HH:mm:ss`;

const defaultConfiguration = { language: 'en', sinceCurrentTime: false, format: 'llll' };

export function getPrettyDate(input: number | string = 0, configuration: DateConfiguration = defaultConfiguration) {
  const { sinceCurrentTime, format } = configuration;

  let momentLocale = 'en-gb';
  switch (i18n.language) {
    case 'be':
      momentLocale = 'nl-be';
      break;
    case 'se':
      momentLocale = 'sv';
      break;
    default:
      momentLocale = i18n.language || momentLocale;
  }

  moment.locale(momentLocale);

  if (input === 0) {
    input = Math.floor(Date.now() / 1000);
  }

  let formattedDate: moment.Moment | string = moment(typeof input === 'string' ? input : input * 1000);

  if (sinceCurrentTime) {
    formattedDate = formattedDate.calendar();
  } else {
    formattedDate = formattedDate.format(format);
  }
  return formattedDate;
}

export interface DateRange {
  startDate: number | null;
  endDate: number | null;
}

export const parseStringDateRange = (daterange: string): DateRange => {
  const [modifier, dateTimestamps] = daterange.split(':');
  switch (modifier.toLowerCase()) {
    case 'gt': {
      // gt:100
      return {
        startDate: parseInt(dateTimestamps, 10),
        endDate: null
      };
    }
    case 'lt': {
      // lt: 1000000
      return {
        startDate: null,
        endDate: parseInt(dateTimestamps, 10)
      };
    }
    case 'bw': {
      if (dateTimestamps && dateTimestamps.indexOf('-') !== -1) {
        const [startDate, endDate] = dateTimestamps.split('-');
        return {
          startDate: parseInt(startDate, 10),
          endDate: parseInt(endDate, 10)
        };
      } else {
        throw new Error('Invalid daterange format');
      }
    }
    default: {
      throw new Error('Invalid daterange format');
    }
  }
};

export const getHumanReadableTimeRange = (rangeValue?: string) => {
  try {
    if (!rangeValue || rangeValue.indexOf(':') === -1) {
      return '';
    }

    const [modifier, dateTimestamps] = rangeValue.split(':');
    switch (modifier.toLowerCase()) {
      case 'gt': {
        return `>${getPrettyDate(parseInt(dateTimestamps, 10), {
          sinceCurrentTime: false,
          format: DATE_FORMAT
        })}`;
      }
      case 'lt': {
        return `<${getPrettyDate(parseInt(dateTimestamps, 10), {
          sinceCurrentTime: false,
          format: DATE_FORMAT
        })}`;
      }
      case 'bw': {
        if (dateTimestamps && dateTimestamps.indexOf('-') !== -1) {
          const startTimestamp = dateTimestamps.split('-')[0];
          const endTimestamp = dateTimestamps.split('-')[1];
          const startDate = getPrettyDate(parseInt(startTimestamp, 10), {
            sinceCurrentTime: false,
            format: DATE_FORMAT
          });
          const endDate = getPrettyDate(parseInt(endTimestamp, 10), {
            sinceCurrentTime: false,
            format: DATE_FORMAT
          });
          return `${startDate}-${endDate}`;
        } else {
          return `${rangeValue}`;
        }
      }
      default: {
        return `${rangeValue}`;
      }
    }
  } catch (error) {
    return `${rangeValue}`;
  }
};
